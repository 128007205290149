import React from 'react';

export default function PrivacyAccordionTitle1() {
    return (
        <div >
            <div>
                AdMob
            </div>
        </div>
    );
  }