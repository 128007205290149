import React from 'react';

export default function PrivacyAccordionContant1() {
    return (
        <div >
            <div className="langCng" lang="en">
                This application uses AdMob (Google Inc.) as an advertisement distribution tool, <br/>
                and AdMob may automatically acquire user information.
            </div>

            <div className="langCng" lang="es">
                Esta aplicación utiliza AdMob (Google Inc.) como herramienta de distribución de publicidad,<br/>
                y AdMob puede adquirir automáticamente la información del usuario.
            </div>

            <div className="langCng" lang="ru">
                Это приложение использует AdMob (Google Inc.) в качестве инструмента распространения рекламы,<br/>
                и AdMob может автоматически получать информацию о пользователях.
            </div>

            <div className="langCng" lang="fr">
                Cette application utilise AdMob (Google Inc.) comme outil de distribution de publicités,<br/>
                et AdMob peut acquérir automatiquement des informations sur les utilisateurs.
            </div>

            <div className="langCng" lang="de">
                Diese Anwendung verwendet AdMob (Google Inc.) als Werbeverteilungstool,<br/>
                und AdMob kann automatisch Nutzerinformationen erfassen.
            </div>

            <div className="langCng" lang="it">
                Questa applicazione utilizza AdMob (Google Inc.) come strumento di distribuzione di annunci pubblicitari,<br/>
                e AdMob può acquisire automaticamente le informazioni sull'utente.
            </div>

            <div className="langCng" lang="hi">
                यह एप्लिकेशन विज्ञापन वितरण उपकरण के रूप में AdMob (Google Inc.) का उपयोग करता है,<br/>
                और AdMob स्वचालित रूप से उपयोगकर्ता जानकारी प्राप्त कर सकता है।
            </div>

            <div className="langCng" lang="id">
                Aplikasi ini menggunakan AdMob (Google Inc.) sebagai alat distribusi iklan,<br/>
                dan AdMob dapat memperoleh informasi pengguna secara otomatis.
            </div>

            <div className="langCng" lang="pt">
                Este aplicativo usa a AdMob (Google Inc.) como ferramenta de distribuição de anúncios,<br/>
                e a AdMob pode adquirir informações do usuário automaticamente.
            </div>

            <div className="langCng" lang="fi">
                Tämä sovellus käyttää AdMobia (Google Inc.) mainosten jakelutyökaluna,<br/>
                ja AdMob voi hankkia käyttäjätietoja automaattisesti.
            </div>

            <div className="langCng" lang="tr">
                Bu uygulama, reklam dağıtım aracı olarak AdMob'u (Google Inc.) kullanır,<br/>
                ve AdMob, kullanıcı bilgilerini otomatik olarak alabilir.
            </div>          

            <div className="langCng" lang="ms">
                Aplikasi ini menggunakan AdMob (Google Inc.) sebagai alat pengedaran iklan,<br/>
                dan AdMob boleh memperoleh maklumat pengguna secara automatik.
            </div>

            <div className="langCng" lang="ta">
                இந்தப் பயன்பாடு AdMob (Google Inc.) ஐ விளம்பர விநியோகக் கருவியாகப் பயன்படுத்துகிறது,<br/>
                மற்றும் AdMob தானாகவே பயனர் தகவலைப் பெறலாம்.
            </div>     

            <div className="langCng" lang="ar">
                يستخدم هذا التطبيق AdMob (Google Inc.) كأداة لتوزيع الإعلانات ،<br/>
                و   AdMob قد يكتسب تلقائيًا معلومات المستخدم.
            </div>

            <div className="langCng" lang="ja">
                このアプリケーションは、AdMob（Google Inc.）を広告配信ツールとして使用します。<br/>
                AdMobはユーザー情報を自動的に取得する場合があります。
            </div>

            <div className="langCng" lang="zh">
                此应用程序使用 AdMob (Google Inc.) 作为广告分发工具，<br/>
                AdMob 可能会自动获取用户信息。
            </div>

            <div className="langCng" lang="ko">
                이 애플리케이션은 AdMob(Google Inc.)를 광고 배포 도구로 사용하며,<br/>
                AdMob은 자동으로 사용자 정보를 획득할 수 있습니다.
            </div>
        </div>
    );
  }